export enum Level {
    Critical = 'critial',
    Error = 'error',
    Alert = 'alert',
    Warning = 'warning',
    Info = 'info',
    Success = 'success',
    Debug = 'debug',
};

export enum Platform {
    Chaturbate = 'chaturbate',
    Clips4sale = 'clips4sale',
    Discord = 'discord',
    Euroescortgirls = 'euroescortgirls',
    Facebook = 'facebook',
    Fansly = 'fansly',
    Fetlife = 'fetlife',
    Instagram = 'instagram',
    Loyalfans = 'loyalfans',
    Manyvids = 'manyvids',
    Myfreecams = 'myfreecams',
    Onlyfans = 'onlyfans',
    Pornhub = 'pornhub',
    Reddit = 'reddit',
    Sextpanther = 'sextpanther',
    Snapchat = 'snapchat',
    Telegram = 'telegram',
    Tiktok = 'tiktok',
    Twitter = 'twitter',
};
