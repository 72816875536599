import React from 'react';

const FanslyIcon = ({ size = 24, color = 'currentColor', strokeWidth = 1.8, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        {...props}
    >
        <path d="M22.0945 5.17832C21.1927 3.86196 19.6654 3.04741 18.0872 2.9165H17.3018C16.1309 3.04741 14.9818 3.49105 14.109 4.29832C11.4909 6.85105 8.93086 9.46196 6.31268 12.0074C5.70904 11.411 5.1054 10.8074 4.50177 10.211C3.86177 9.57105 3.51995 8.63287 3.70904 7.73105C3.96358 6.40014 5.33813 5.3965 6.68358 5.62196C7.9854 5.76014 8.68358 7.01105 9.62177 7.76014L11.5054 5.97105C10.3345 4.47287 8.74904 3.03287 6.74904 2.9165H6.26177C4.98177 2.96741 3.67995 3.37469 2.71995 4.24741C1.60722 5.21469 1.08358 6.69105 0.923584 8.12378V8.58196C0.981766 9.81105 1.51267 10.9892 2.37086 11.8692C4.96722 14.502 7.5854 17.1056 10.2036 19.7092C10.6981 20.1965 11.149 20.7347 11.7527 21.091H12.1963C12.8581 20.8656 13.2654 20.2547 13.7599 19.8038C15.6872 17.8401 17.7018 15.9565 19.5781 13.942L13.9345 8.2765C14.5745 7.6365 15.1999 6.98923 15.8472 6.36378C16.3709 5.84014 17.1054 5.54196 17.8545 5.60014C19.3672 5.62196 20.6254 7.17105 20.3418 8.65469C20.1818 9.94923 18.9599 10.6474 18.2036 11.5783L20.109 13.3965C20.7418 12.8729 21.3381 12.2329 21.8763 11.6147C23.3818 9.84014 23.4618 7.04741 22.0799 5.17105L22.0945 5.17832ZM14.6836 13.6074C14.9381 15.222 13.3963 16.8074 11.7745 16.582C10.189 16.5238 9.03267 14.7929 9.43267 13.2947C9.65813 12.3056 10.509 11.5056 11.5054 11.3383C11.6872 11.2947 11.869 11.2801 12.0509 11.2801C13.3236 11.2947 14.5454 12.3056 14.6836 13.6001V13.6074Z" />
    </svg>
);

export default FanslyIcon;
