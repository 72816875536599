import { IModalContent, IToastContent, IWindowContent } from '@/types/api';
import type { ReactNode } from 'react';
import React, { createContext, useContext } from 'react';

type UiContextType = {
    showModal: (content: ReactNode) => void;
    hideModal: () => void;
    showDialog: (content: ReactNode) => void;
    hideDialog: () => void;
    showDrawer: (content: ReactNode) => void;
    hideDrawer: () => void;
    showSheet: (content: ReactNode) => void;
    hideSheet: () => void;
    showWindow: (content: ReactNode) => void;
    hideWindow: () => void;
    showToast: (content: IToastContent) => void;
    hideToast: (toastId?: string) => void;
    showLoadingToast: (toastId: string) => void;
    show: (content: ReactNode, type: 'modal' | 'drawer' | 'sheet' | 'dialog') => void;
    showDefaultErrorToast: (toastId: string, description?: string) => void;
    showDefaultSuccessToast: (toastId: string, description?: string) => void;
};

export const UiContext = createContext<UiContextType | undefined>(undefined);

export const useUi = () => {
    const context = useContext(UiContext);
    if (!context) {
        throw new Error('useUi must be used within an UiProvider');
    }
    return context;
};
