import React from 'react';

const MyFreeCamsIcon = ({ size = 24, color = 'currentColor', strokeWidth = 0.2, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        {...props}
    >
        <path d="M5.04495 21H1V3H7.14065L12 14.2425L16.9417 3H23V21H18.9734V8.1739L13.3453 21H10.6272L5.04495 8.1739V21Z" fill="currentColor" />
    </svg>
);

export default MyFreeCamsIcon;
