import type { ReactNode } from 'react';
import Clips4saleIcon from '@/components/icons/Clips4saleIcon';
import EuroGirlsEscortIcon from '@/components/icons/EuroGirlsEscortIcon';
import FanslyIcon from '@/components/icons/FanslyIcon';
import FetlifeIcon from '@/components/icons/FetlifeIcon';
import LoyalFansIcon from '@/components/icons/LoyalFansIcon';
import ManyvidsIcon from '@/components/icons/ManyvidsIcon';
import MyFreeCamsIcon from '@/components/icons/MyFreeCamsIcon';
import PornhubIcon from '@/components/icons/PornhubIcon';
import SextPantherIcon from '@/components/icons/SextPantherIcon';
import { routing } from '@/libs/i18nNavigation';
import { Platform } from '@/types/enum';
import { SiDiscord, SiFacebook, SiInstagram, SiOnlyfans, SiReddit, SiSnapchat, SiTelegram, SiTiktok, SiX } from '@icons-pack/react-simple-icons';
import { Link2Icon } from 'lucide-react';
import ChaturbateIcon from '@/components/icons/ChaturbateIcon';
import { logWarning } from './Logger';

export const getBaseUrl = () => {
    return process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';
};

export const getApiUrl = (endpoint: string) => {
    return `${process.env.NEXT_PUBLIC_API_URL}/${endpoint}`;
};

export const getI18nPath = (url: string, locale: string) => {
    if (locale === routing.defaultLocale) return url;

    return `/${locale}${url}`;
};

export const getPlatformIcon = (platform: Platform, size: number): ReactNode => {
    switch (platform) {
        case Platform.Clips4sale:
            return <Clips4saleIcon size={size} />;
        case Platform.Discord:
            return <SiDiscord size={size} />;
        case Platform.Euroescortgirls:
            return <EuroGirlsEscortIcon size={size} />;
        case Platform.Facebook:
            return <SiFacebook size={size} />;
        case Platform.Fansly:
            return <FanslyIcon size={size} />;
        case Platform.Fetlife:
            return <FetlifeIcon size={size} />;
        case Platform.Instagram:
            return <SiInstagram size={size} />;
        case Platform.Loyalfans:
            return <LoyalFansIcon size={size} />;
        case Platform.Manyvids:
            return <ManyvidsIcon size={size} />;
        case Platform.Myfreecams:
            return <MyFreeCamsIcon size={size} />;
        case Platform.Onlyfans:
            return <SiOnlyfans size={size} />;
        case Platform.Pornhub:
            return <PornhubIcon size={size} />;
        case Platform.Reddit:
            return <SiReddit size={size} />;
        case Platform.Sextpanther:
            return <SextPantherIcon size={size} />;
        case Platform.Snapchat:
            return <SiSnapchat size={size} />;
        case Platform.Telegram:
            return <SiTelegram size={size} />;
        case Platform.Tiktok:
            return <SiTiktok size={size} />;
        case Platform.Twitter:
            return <SiX size={size} />;
        case Platform.Chaturbate:
            return <ChaturbateIcon size={size} />;
        default:
            logWarning(`getPlatformIcon: icon not found for ${platform}. Default icon returned.`);
            return <Link2Icon size={size} />;
    };
};
