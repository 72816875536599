import { Level } from "@/types/enum";

class UnauthorizedException extends Error {
    logLevel: Level;
    logContext?: any;
    userId?: string | null;

    constructor(url: string, logContext?: object | null, userId?: string | null) {
        super(`Unauthorized request to ${url}.`);
        this.logLevel = Level.Warning;
        this.logContext = logContext;
        this.userId = userId;
    }
};

export default UnauthorizedException;
