import React from 'react';

const LoyalFansIcon = ({ size = 24, color = 'currentColor', ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke={color}
        strokeWidth={0.2}
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        {...props}
    >
        <path d="M1.23425 6.91884L7.21368 18.2811V23.3623H10.5051V18.2811L22.7657 0.637695L9.01025 12.5966L1.23425 6.91884Z" fill="currentColor" />
        <path d="M9.01025 9.90161C10.1653 9.90161 11.1017 8.96524 11.1017 7.81018C11.1017 6.65511 10.1653 5.71875 9.01025 5.71875C7.85519 5.71875 6.91882 6.65511 6.91882 7.81018C6.91882 8.96524 7.85519 9.90161 9.01025 9.90161Z" fill="currentColor" />
    </svg>
);

export default LoyalFansIcon;
