import React from 'react';

const ChaturbateIcon = ({ size = 24, color = 'currentColor', strokeWidth = 0.1, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        {...props}
    >
        <path d="M20.6786 14.3321L20.2468 14.2666L20.0617 14.8993C19.671 16.2521 18.4372 17.7793 17.2856 18.3466C16.2985 18.8266 15.1264 18.6521 14.4066 17.8884C13.2962 16.7102 13.2756 14.2666 14.3655 11.2775L14.6945 10.4048L15.3526 10.3175C18.0259 9.99025 20.2674 9.03025 21.6863 7.54661C23.5576 5.60479 23.4137 3.18297 21.3573 2.09206C19.8561 1.28479 17.6558 1.30661 15.9284 2.1357C13.8926 3.0957 11.9801 5.4957 11.0342 8.22297C10.9313 8.52843 10.808 8.81206 10.7668 8.87752C10.5406 9.16116 7.9907 7.93934 7.00363 7.06661C6.36615 6.49934 5.68754 5.62661 5.27626 4.7757C5.02949 4.25206 4.72103 3.11752 4.72103 2.65934C4.72103 2.04843 4.61821 2.04843 3.56945 2.68115C2.47957 3.35752 1.45137 4.33934 1.16347 4.99388C0.875578 5.62661 0.978398 6.4557 1.38968 7.06661C2.35618 8.46297 4.98837 9.5757 8.56649 10.1212C9.14228 10.2084 9.77976 10.2957 9.96484 10.2957C10.1499 10.2957 10.3144 10.3175 10.3556 10.3612C10.3761 10.383 10.3556 10.7757 10.2527 11.2339C10.1499 11.7793 10.0882 12.6739 10.0882 13.983C10.0882 15.7284 10.1088 16.0339 10.3761 17.0375C10.808 18.7175 11.3015 19.6775 12.3091 20.7248C12.8849 21.3357 13.3579 21.7284 13.7897 21.9466C15.1881 22.6666 17.0388 22.863 18.3138 22.4484C20.2468 21.8157 21.9742 19.9175 22.4883 17.823C22.9612 15.903 22.3032 14.6375 20.6786 14.3975V14.3321ZM15.661 8.74661C16.7509 7.21934 18.0876 6.4557 18.8073 6.97934C19.3009 7.32843 19.0541 8.04843 18.2521 8.52843C17.4912 8.98661 15.4348 9.44479 15.4348 9.16116C15.4348 8.87752 15.5377 8.92116 15.661 8.74661Z" />
    </svg>
);

export default ChaturbateIcon;
