import React from 'react';

const Clips4saleIcon = ({ size = 24, color = 'currentColor', strokeWidth = 0.1, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        {...props}
    >
        <path d="M13.1186 1.00582C12.8778 1.85533 12.6369 2.70484 12.3898 3.54853C11.9525 5.03227 11.5152 6.51018 11.0779 7.99392C11.0462 8.09283 11.0335 8.19175 11.0018 8.33139C11.6926 8.33139 12.3454 8.33721 12.9982 8.31976C13.0679 8.31976 13.1566 8.19175 13.1883 8.10447C13.3848 7.52261 13.5622 6.94076 13.7397 6.35308C13.7841 6.20762 13.8284 6.11452 14.0249 6.11452C15.6474 6.12034 17.2761 6.11452 18.8986 6.12034C18.9176 6.12034 18.9366 6.13198 19 6.14361C16.6487 11.7643 14.3038 17.3793 11.9588 23C11.9398 23 11.9208 22.9942 11.9018 22.9884C12.1933 19.4798 12.4785 15.977 12.77 12.4335H5C5.14577 11.9331 5.27886 11.4676 5.41829 11.0079C6.3943 7.70881 7.37664 4.4155 8.35265 1.11637C8.36532 1.08146 8.35899 1.04073 8.36532 1H13.1186V1.00582Z" />
    </svg>
);

export default Clips4saleIcon;
