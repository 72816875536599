import { Level } from '@/types/enum';
import { ApiEndpoints } from './AppConfig';
import { getApiUrl } from './Helpers';

export async function logError(logMessage: string, logContext?: any, userId?: string | null): Promise<void> {
    log(logMessage, Level.Error, logContext, userId);
}

export async function logWarning(logMessage: string, logContext?: any, userId?: string | null): Promise<void> {
    log(logMessage, Level.Warning, logContext, userId);
}

export async function logAlert(logMessage: string, logContext?: any, userId?: string | null): Promise<void> {
    log(logMessage, Level.Alert, logContext, userId);
}

export async function logInfo(logMessage: string, logContext?: any, userId?: string): Promise<void> {
    log(logMessage, Level.Info, logContext, userId);
}

export async function logSuccess(logMessage: string, logContext?: any, userId?: string): Promise<void> {
    log(logMessage, Level.Success, logContext, userId);
}

export async function logDebug(logMessage: string, logContext?: any, userId?: string): Promise<void> {
    log(logMessage, Level.Debug, logContext, userId);
}

async function log(logMessage: string, logLevel: Level, logContext?: any, userId?: string | null): Promise<void> {
    try {
        await fetch(getApiUrl(ApiEndpoints.service.LOGGER), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ logMessage, logContext, logLevel, userId }),
        });
    } catch (error: any) {
        console.error('Failed on sending log message.');
    }
};
