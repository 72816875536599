import { IUser } from '@/types/api';
import { createContext, useContext } from 'react';

type AuthContextType = {
    user: IUser | null;
    totalLinks: number;
    totalFriends: number
    login: () => Promise<void>;
    logout: () => Promise<boolean>;
    toDashboard: () => void;
    updateUserAvatar: (avatarUrl: string) => void;
    updateUsername: (username: string, initials: string) => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
