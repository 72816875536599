import React from 'react';

const FetlifeIcon = ({ size = 24, color = 'currentColor', strokeWidth = 2, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        {...props}
    >
        <path d="M21.415 11.7412C21.415 16.389 15.859 18.8584 11.7075 23.0037C7.5561 18.8584 2 16.389 2 11.7412C2 10.7729 2.28445 9.87092 2.77437 9.114C2.66355 8.58028 2.60205 8.05331 2.58655 7.53969C2.57052 7.01332 2.60209 6.50113 2.67138 6.00811C2.81054 5.0214 3.09556 4.11336 3.46447 3.27987C3.6494 2.86303 3.85621 2.46421 4.08173 2.08311C4.1945 1.89261 4.31206 1.70652 4.43461 1.52541C4.55735 1.34459 4.68403 1.16741 4.81983 1C4.79516 1.21331 4.78394 1.42585 4.7776 1.63733C4.77165 1.84872 4.77252 2.05906 4.77933 2.26777C4.79305 2.68509 4.83058 3.09598 4.89161 3.49567C5.0132 4.29485 5.23105 5.05072 5.54237 5.7078C5.69775 6.03628 5.87596 6.33947 6.07308 6.61124C6.15225 6.72053 6.23438 6.82466 6.31929 6.92362C6.49475 6.90442 6.67304 6.89459 6.85361 6.89459C9.53429 6.89459 11.7075 9.14706 11.7075 9.14706C11.7075 9.14706 13.8808 6.89459 16.5614 6.89459C16.7432 6.89459 16.9227 6.90456 17.0993 6.92401C17.1842 6.82493 17.2665 6.72067 17.3458 6.61124C17.5429 6.33947 17.7212 6.03628 17.8765 5.7078C18.1878 5.05072 18.4057 4.29485 18.5274 3.49567C18.5883 3.09598 18.626 2.68509 18.6396 2.26777C18.6464 2.05906 18.6473 1.84872 18.6414 1.63733C18.635 1.42585 18.6238 1.21331 18.5992 1C18.735 1.16741 18.8616 1.34459 18.9843 1.52541C19.1069 1.70652 19.2245 1.89261 19.3372 2.08311C19.5628 2.46421 19.7696 2.86303 19.9545 3.27987C20.3236 4.11336 20.6085 5.0214 20.7478 6.00811C20.817 6.50113 20.8486 7.01332 20.8326 7.53969C20.817 8.05475 20.7551 8.58322 20.6408 9.114C21.1306 9.87092 21.415 10.7729 21.415 11.7412Z" />
    </svg>
);

export default FetlifeIcon;
