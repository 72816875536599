import React from 'react';

const SextPantherIcon = ({ size = 24, color = 'currentColor', strokeWidth = 0.1, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        width={size}
        height={size}
        {...props}
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M11.8263 4.64782C12.8999 4.51082 13.3778 4.81265 13.3778 4.81265C13.6237 5.14862 13.6588 5.58615 13.6588 5.58615C13.5651 5.7923 13.313 5.66976 13.313 5.66976C13.0241 5.37499 12.3099 5.09154 11.8086 4.91844C11.8237 4.86234 11.8323 4.80178 11.8323 4.73817C11.8323 4.70718 11.8301 4.67706 11.8263 4.64782ZM17.8901 12.4693C18.3545 11.7481 18.8109 10.9818 18.779 10.801C18.7153 10.4395 18.4853 10.4394 18.5611 10.154C18.5964 10.0211 18.7551 9.60999 18.8873 9.15267C18.9995 9.19573 19.1093 9.24064 19.2153 9.28782C19.57 9.4445 19.8845 9.6251 20.1458 9.79276C20.6667 10.1321 20.9729 10.4182 20.9989 10.389C21.0195 10.368 20.7558 10.0302 20.2521 9.62901C19.9997 9.43091 19.688 9.21813 19.3297 9.0297C19.2135 8.96838 19.0921 8.90945 18.9675 8.85323C19.0285 8.60316 19.0725 8.35417 19.0766 8.14182C19.4691 8.22445 19.8337 8.33036 20.1218 8.43071C20.3165 8.50063 20.4805 8.55956 20.6026 8.60348C20.7193 8.64447 20.7896 8.66122 20.7972 8.64556C20.8049 8.63001 20.7495 8.58272 20.645 8.51117C20.5419 8.43669 20.3846 8.34938 20.1923 8.24685C19.8858 8.08876 19.4776 7.92425 19.0319 7.7965C19.0256 7.77888 19.0187 7.76192 19.011 7.74605C18.7399 7.19142 17.006 5.59137 16.7057 5.46165C16.4055 5.33194 15.3011 4.9316 15.0866 4.29021C14.8722 3.64881 14.5172 2.84335 14.0607 2.58773C13.6043 2.33222 11.5711 1.78129 10.8205 1.55274C8.6143 0.880793 7.92006 1.43085 7.18702 0.857308C6.27455 0.143611 0.43556 0.143611 4.31898 4.2851V11.8387C4.31898 11.8085 2.98801 11.6965 3.26034 11.8457C3.9565 12.2271 4.31898 12.8654 4.31898 13.2936V23.2082C5.42853 23.4028 6.29189 23.5 7.01291 23.5C7.38347 23.5 7.77294 23.4783 8.20526 23.4352C8.63769 23.3916 8.86825 23.3161 9.64487 23.2082V15.0536C11.1983 15.0492 11.9855 15.2363 12.5274 16.4683C12.6436 16.7326 12.8972 16.9065 13.174 16.9216C14.0476 16.9698 15.8608 16.8663 16.3006 15.2478L16.5346 14.2387C16.7978 13.0919 17.2608 13.4464 17.8901 12.4693Z" fill="currentColor" />
    </svg>
);

export default SextPantherIcon;
